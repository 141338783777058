import React from "react";
import { Box } from "theme-ui";

import AddressData from "app/forms/AddressData";
import Attachments from "app/forms/Attachments";
import ChurchData from "app/forms/ChurchData";
import ContactInformation from "app/forms/ContactInformation";
import GeneralInformation from "app/forms/GeneralInformation";
import PersonalData from "app/forms/PersonalData";
import StatusData from "app/forms/StatusData";

export const MemberForm = ({ isDisabled = false }) => {
  return (
    <>
      <Box variant="layout.admin.panel">
        <StatusData.Page isDisabled={isDisabled} />
      </Box>
      <Box variant="layout.admin.panel">
        <PersonalData.Page />
      </Box>
      <Box variant="layout.admin.panel">
        <ChurchData.Page />
      </Box>
      <Box variant="layout.admin.panel">
        <AddressData.Page />
      </Box>
      <Box variant="layout.admin.panel">
        <GeneralInformation.Page />
      </Box>
      <Box variant="layout.admin.panel">
        <ContactInformation.Page />
      </Box>
      <Box variant="layout.admin.panel" sx={{ mb: 6 }}>
        <Attachments.Page />
      </Box>
    </>
  );
};
