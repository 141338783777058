import { ApolloProvider } from "@apollo/react-hooks";
import { ThemeProvider } from "@chakra-ui/core";
import { theme as initialTheme } from "@chakra-ui/core";
import ApolloClient from "apollo-boost";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import {
  SpinnerAbsolute,
  MainLayout,
  NavbarArea,
  FooterArea,
  ContentArea,
  SidebarArea,
  EmptyLayout,
} from "@members/layout";
import { theme as defaultTheme } from "@members/themes";
import { GlobalStyles } from "@members/themes";

import { AuthenticationRoutes, ProtectedRoute } from "./authentication";
import { AUTHENTICATION_ROUTES } from "./authentication/AuthenticationRoutes";
import { CongregationRoutes } from "./congregations";
import { DashboardRoutes } from "./dashboard";
import { MemberRoutes } from "./members";
import { MemberPrint } from "./members/MemberPrint";
import { MEMBERS_ROUTES } from "./members/MemberRoutes";
import { UserRoutes } from "./users";
import { Navbar, Footer, Sidebar } from "app/layout";

export const client: any = new ApolloClient({
  uri: `${process.env.REACT_APP_API}/api`,
  request: (operation) => {
    const token = JSON.parse(window.localStorage.getItem("auth") || "");
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  },
  onError: ({ graphQLErrors }) => {
    if (graphQLErrors) {
      const [error] = graphQLErrors;
      if (error?.extensions?.exception?.output?.statusCode === 401) {
        window.location.href = AUTHENTICATION_ROUTES.logout;
      }
    }
  },
});

const ScrollTo = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return null;
};

export const LoginContainer = () => {
  return (
    <EmptyLayout>
      <NavbarArea>
        <Navbar />
      </NavbarArea>
      <ContentArea>
        <Route component={AuthenticationRoutes} />
      </ContentArea>
      <FooterArea>
        <Footer />
      </FooterArea>
    </EmptyLayout>
  );
};

export const AppContainer = () => {
  return (
    <Suspense fallback={<SpinnerAbsolute />}>
      <MainLayout>
        <NavbarArea>
          <Navbar />
        </NavbarArea>
        <SidebarArea>
          <Sidebar />
        </SidebarArea>
        <ContentArea>
          <MemberRoutes />
          <DashboardRoutes />
          <CongregationRoutes />
          <UserRoutes />
        </ContentArea>
        <FooterArea>
          <Footer />
        </FooterArea>
      </MainLayout>
    </Suspense>
  );
};

export const PrintContainer = () => {
  return <Route exact path={MEMBERS_ROUTES.print} component={MemberPrint} />;
};

export const theme: any = { ...initialTheme, ...defaultTheme };
export const App = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <BrowserRouter>
          <Route component={ScrollTo} />
          <Switch>
            <Route
              path={AUTHENTICATION_ROUTES.base}
              component={LoginContainer}
            />
            <ProtectedRoute
              exact
              path={MEMBERS_ROUTES.print}
              component={MemberPrint}
            />

            <ProtectedRoute component={AppContainer} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
};
