import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { Flex, Text } from "theme-ui";

import { USERS_QUERY } from "@members/graphql";
import { SpinnerAbsolute, Table } from "@members/layout";

export const UserList = () => {
  const { data, loading } = useQuery(USERS_QUERY);
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "USERS",
        columns: [
          {
            Header: "Username",
            accessor: "username",
          },
          {
            Header: "Email",
            accessor: "email",
          },
        ],
      },
    ],
    []
  );
  return (
    <>
      <Flex
        variant="layout.admin.panel"
        sx={{
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}>
        <Text variant="styles.h2" sx={{ m: 0 }}>
          Utilizadores
        </Text>
      </Flex>
      {loading && <SpinnerAbsolute />}
      {data?.users && (
        <Table.TableUi columns={columns} data={data.users} onClick={null} />
      )}
    </>
  );
};

export default UserList;
