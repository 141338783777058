import { Modal, ModalOverlay, ModalContent, theme } from "@chakra-ui/core";
import React from "react";
import { Styled, Button, ThemeProvider, Box, Flex, Text } from "theme-ui";

import { theme as defaultTheme } from "@members/themes";

export const DeleteModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <ThemeProvider theme={theme}>
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ThemeProvider theme={defaultTheme}>
            <Box p={3}>
              <Styled.h3>Remover Registo</Styled.h3>
              <Text pb={4}>Tem a certeza que pretende remover o registo?</Text>

              <Flex sx={{ justifyContent: "flex-end" }}>
                <Button variant="outline" mr={3} onClick={onClose}>
                  Fechar
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    onConfirm();
                    onClose();
                  }}>
                  Remover
                </Button>
              </Flex>
            </Box>
          </ThemeProvider>
        </ModalContent>
      </Modal>
    </ThemeProvider>
  );
};
