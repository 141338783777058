"use strict";

exports.__esModule = true;
exports.file = exports.date = void 0;

var _dateFns = require("date-fns");

var _yup = require("yup");

var date = function date() {
  return (0, _yup.mixed)().required("Obrigatório").test("invalidDate", "Data inválida", function (value) {
    try {
      var _date = (0, _dateFns.parse)(value, "dd/MM/yyyy", new Date());

      (0, _dateFns.format)(_date, "dd/MM/yyyy");

      if (_date > new Date() || _date < new Date("01-01-1900")) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  });
};

exports.date = date;

var file = function file(_ref) {
  var MAX_SIZE = _ref.MAX_SIZE,
      SUPPORTED_FORMATS = _ref.SUPPORTED_FORMATS;
  return (0, _yup.mixed)().required("Obrigatório").test("fileFormat", "Formato não suportado", function (value) {
    return value && SUPPORTED_FORMATS.includes(value.mime);
  }).test("fileSize", "Ficheiro muito grande", function (value) {
    return value && value.size <= MAX_SIZE;
  });
};

exports.file = file;