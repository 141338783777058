import { useFormikContext } from "formik";
import React from "react";
import { Box, Styled, Divider, Grid, Button, Image, Label } from "theme-ui";
import { object, string, number } from "yup";

import {
  TextField,
  DateField,
  SelectField,
  PictureUploadField,
} from "@members/fields";
import { date, file } from "@members/fields";
import { CIVIL_STATUS_QUERY } from "@members/graphql";
import { useQuerySelectOptions, useQueryRadioOptions } from "@members/hooks";

import { Title } from "app/utils";

const MAX_SIZE = 1024 * 15;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const validationSchema = object().shape({
  name: string().min(2, "Muito curto").required("Obrigatório"),
  dateOfBirth: date(),
  civilStatus: number().positive().required("Obrigatório"),
  fatherName: string().required("Obrigatório"),
  motherName: string().required("Obrigatório"),
  picture: file({ MAX_SIZE, SUPPORTED_FORMATS }),
});

const initialValues: any = {
  name: "",
  dateOfBirth: "",
  civilStatus: "",
  fatherName: "",
  motherName: "",
  picture: "",
};

export const PersonalData = () => {
  const { values } = useFormikContext<any>();
  const civilStatus = useQuerySelectOptions(
    CIVIL_STATUS_QUERY,
    "civilStatuses"
  );

  return (
    <>
      <Styled.h3>Dados pessoais</Styled.h3>
      <Divider />

      <Grid
        sx={{
          gridTemplateColumns: ["1fr", null, "1fr 1fr 150px"],
          gap: [0, null, 4],
        }}>
        <Box sx={{ gridColumn: [1, null, "1 / 3"], gridRow: 1 }}>
          <TextField label="Nome Completo" name="name" required />
        </Box>
        <Box sx={{ gridColumn: 1, gridRow: 2 }}>
          <DateField label="Data de Nascimento" name="dateOfBirth" required />
        </Box>
        <Box sx={{ gridColumn: [1, null, 2], gridRow: [3, null, 2] }}>
          <SelectField label="Estado Cívil" name="civilStatus" required>
            {civilStatus.options}
          </SelectField>
        </Box>
        <Box sx={{ gridColumn: [1, null, 3], gridRow: [4, null, "1 / 4"] }}>
          <PictureUploadField label="Foto" name="picture" required />
          {values?.picture?.url && (
            <a
              href={values.picture.url}
              target="_blank"
              rel="noopener noreferrer">
              <Button type="button" variant="small">
                Abrir foto
              </Button>
            </a>
          )}
        </Box>
      </Grid>

      <Divider />
      <TextField label="Nome do Pai" name="fatherName" required />
      <TextField label="Nome da Mãe" name="motherName" required />
    </>
  );
};

export const PersonalDataPrint = () => {
  const { values } = useFormikContext<any>();
  const civilStatus = useQueryRadioOptions(CIVIL_STATUS_QUERY, "civilStatuses");

  const memberCivilStatus = civilStatus?.options?.find(
    (i) => i.id == values.civilStatus
  );

  const picture = values.picture.formats?.thumbnail?.url || values.picture.url;

  return (
    <>
      <Title>Dados pessoais</Title>
      <Grid
        sx={{
          gridTemplateColumns: "1fr 1fr 160px",
          rowGap: 2,
          columnGap: 2,
          mb: 2,
          px: 2,
        }}>
        <Box sx={{ gridColumn: "1 / 3", gridRow: 1 }}>
          <Label mb={1} variant="label.small">
            Nome Completo
          </Label>
          {values.name}
        </Box>
        <Box sx={{ gridColumn: 1, gridRow: 2 }}>
          <Label mb={1} variant="label.small">
            Data de Nascimento
          </Label>
          {values.dateOfBirth}
        </Box>
        <Box sx={{ gridColumn: 2, gridRow: 2 }}>
          <Label mb={1} variant="label.small">
            Estado Cívil
          </Label>
          {memberCivilStatus?.label}
        </Box>
        <Box sx={{ gridColumn: 1, gridRow: 3 }}>
          <Label mb={1} variant="label.small">
            Nome do Pai
          </Label>
          {values.fatherName}
        </Box>
        <Box sx={{ gridColumn: 2, gridRow: 3 }}>
          <Label mb={1} variant="label.small">
            Nome da Mãe
          </Label>
          {values.motherName}
        </Box>
        <Box sx={{ gridRow: "1 / 4" }}>
          <Image
            src={picture}
            sx={{
              objectFit: "cover",
              objectPosition: "top",
              height: "140px",
              width: "120px",
              p: 0,
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default {
  Page: PersonalData,
  Print: PersonalDataPrint,
  initialValues,
  validationSchema,
};
