import { useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Icon } from "@tooca-ui/icons";
import { Formik, Form } from "formik";
import React, { useEffect } from "react";
import { Box, Flex, Text, Button, Label } from "theme-ui";

import { CREATE_CONGREGATION_MUTATION } from "@members/graphql";
import { SpinnerAbsolute } from "@members/layout";

import { CongregationForm } from "./CongregationForm";
import { CONGREGATION_ROUTES } from "./CongregationRoutes";
import { validationSchema, options, initialValues } from "./CongregationUtils";
import { useRedirect, useAlertSuccess } from "app/utils";

export const CongregationAdd = (props) => {
  const setRedirect = useRedirect();
  const [createCongregation, { loading, data, error }] = useMutation(
    CREATE_CONGREGATION_MUTATION,
    options
  );
  const alertSuccess = useAlertSuccess();

  const createCongregationHandler = (data) => {
    createCongregation({
      variables: { createCongregationInput: { data } },
    }).catch((e) => {
      Sentry.withScope(function (scope) {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    });
  };

  useEffect(() => {
    if (data?.createCongregation.congregation.id) {
      setRedirect(CONGREGATION_ROUTES.list);
      alertSuccess("Registo adicionado com sucesso");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Flex
        variant="layout.admin.panel"
        sx={{
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}>
        <Text variant="styles.h2" sx={{ m: 0 }}>
          Nova Congregação
        </Text>
      </Flex>

      <Formik
        validateOnMount={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          createCongregationHandler(values);
        }}>
        {({ isValid }) => {
          return (
            <Form autoComplete="off" noValidate={true}>
              <fieldset
                disabled={loading}
                style={{ border: "none", padding: 0 }}>
                <CongregationForm />
                <Box
                  sx={{
                    position: "fixed",
                    bottom: "50px",
                    left: "276px",
                    right: "26px",
                  }}>
                  {loading && <SpinnerAbsolute />}
                  <Flex
                    variant="layout.admin.panel"
                    sx={{
                      mb: 0,
                      bg: "white",
                      width: "100%",
                      p: 3,
                      justifyContent: "space-between",
                    }}>
                    <Box>
                      {error && (
                        <Label
                          variant="label.error"
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          Erro ao guardar, tente novamente...
                        </Label>
                      )}
                    </Box>
                    <Box>
                      <Button
                        variant="outline"
                        mr={2}
                        onClick={() => setRedirect(CONGREGATION_ROUTES.list)}>
                        <Flex sx={{ alignItems: "center" }}>
                          <Icon name="Close" size={15} color="danger" />
                          <Text ml={2}>Cancelar</Text>
                        </Flex>
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={!isValid || loading}>
                        Guardar
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CongregationAdd;
