import React from "react";
import { Switch, Route } from "react-router-dom";

import { CongregationAdd } from "./CongregationAdd";
import { CongregationList } from "./CongregationList";
import { CongregationView } from "./CongregationView";

export enum CONGREGATION_ROUTES {
  list = `/congregacoes`,
  edit = `/congregacoes/:id`,
  add = `/congregacoes/novo`,
}

export default () => (
  <Switch>
    <Route exact path={CONGREGATION_ROUTES.list} component={CongregationList} />
    <Route exact path={CONGREGATION_ROUTES.add} component={CongregationAdd} />
    <Route exact path={CONGREGATION_ROUTES.edit} component={CongregationView} />
  </Switch>
);
