import { useDisclosure } from "@chakra-ui/core";
import { Icon } from "@tooca-ui/icons";
import { DocumentNode } from "graphql";
import React from "react";
import { Button, Flex, Text } from "theme-ui";

import { PrintModal } from "./modal";

interface PrintProps {
  member: any;
  disabled?: boolean;
  query: DocumentNode;
}

export const PrintPdf: React.FC<PrintProps> = ({ member, disabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        sx={{ mr: 3, position: "relative" }}
        type="button"
        variant="outline"
        disabled={disabled}
        onClick={onOpen}>
        <Flex sx={{ alignItems: "center" }}>
          <Icon name="Print" size={15} />
          <Text ml={2}>Exportar PDF</Text>
        </Flex>
      </Button>
      <PrintModal isOpen={isOpen} onClose={onClose} member={member} />
    </>
  );
};
