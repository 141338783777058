import { useState, useEffect, useCallback } from "react";

interface FileProps {
  url: string;
  name: string;
}

export const useDownload = () => {
  const [file, setFile] = useState<FileProps>({ url: "", name: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (file.url) {
      setLoading(true);
      fetch(file.url)
        .then((res: any) => res.blob())
        .then((blob) => {
          setLoading(false);
          const data = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = data;
          link.download = file.name;
          link.click();
        })
        .catch(() => setError(true));
    }
  }, [file]);

  const download = useCallback((props: FileProps) => setFile(props), [setFile]);

  return { loading, error, download };
};
