import { useEffect, useState, useCallback } from "react";

import { currentConfig } from "@members/config";

const API = currentConfig.API;

export const useAuthentication = (token: string | null = null) => {
  const { login, response, loading, error } = useLogin();
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage("auth", token);

  useEffect(() => {
    if (isAuthenticated && token) {
      setIsAuthenticated(token);
    }

    if (!isAuthenticated && response?.jwt) {
      setIsAuthenticated(response.jwt);
    }
  }, [response, isAuthenticated, setIsAuthenticated]);

  const logout = () => setIsAuthenticated(null);

  return { login, logout, response, loading, error, isAuthenticated };
};

export const useLogin = () => {
  const [response, setResponse] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [error, setError] = useState<any>(false);

  const login = useCallback((form) => {
    setLoading(true);
    setError(false);
    const xhr = new XMLHttpRequest();
    const formData = new FormData(form.current);
    xhr.open("POST", `${API}/auth/local/`);
    xhr.send(formData);

    xhr.onload = () => {
      if (xhr.status === 200) {
        const { jwt } = JSON.parse(xhr.responseText);
        setResponse({
          jwt,
        });
      } else {
        setResponse(null);
        setError(true);
      }
      setLoading(false);
    };
    xhr.onerror = () => {
      setError("Erro ao enviar o ficheiro");
      setLoading(false);
    };
  }, []);

  return { login, response, loading, error };
};

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return [storedValue, setValue];
};
