import React, { useEffect } from "react";
import { Redirect } from "react-router";

import { useAuthentication } from "app/authentication/hooks";
import { MEMBERS_ROUTES } from "app/members/MemberRoutes";

export const LogoutView = () => {
  const { logout, isAuthenticated } = useAuthentication();

  useEffect(() => {
    logout();
  }, [logout]);

  if (!isAuthenticated) {
    return <Redirect to={MEMBERS_ROUTES.list} />;
  }

  return null;
};
