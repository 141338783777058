import { useQuery } from "@apollo/react-hooks";
import Logo from "assets/logo.png";
import { format } from "date-fns";
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Box, Flex, Image, Styled } from "theme-ui";

import { MEMBER_QUERY } from "@members/graphql";

import { MEMBERS_ROUTES } from "./MemberRoutes";
import { deserializeMember } from "./MemberUtils";
import AddressData from "app/forms/AddressData";
import Attachments from "app/forms/Attachments";
import ChurchData from "app/forms/ChurchData";
import ContactInformation from "app/forms/ContactInformation";
import GeneralInformation from "app/forms/GeneralInformation";
import PersonalData from "app/forms/PersonalData";

export const MemberPrintStatic = ({ member, attachments }) => {
  return (
    <>
      {member?.id && (
        <Formik
          validateOnMount={false}
          initialValues={member}
          onSubmit={() => {}}>
          {() => {
            return (
              <Box
                sx={{
                  color: "text",
                  bg: "white",
                  fontSize: 2,
                  pt: 2,
                  px: 4,
                }}>
                <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
                  <Box mr={4}>
                    <Image
                      src={Logo}
                      sx={{
                        width: "80px",
                        mb: 2,
                      }}
                    />
                  </Box>
                  <Styled.h3>
                    Igreja Assembleia de Deus <br />
                    Ministério Semeadores de Boas Novas
                  </Styled.h3>
                </Flex>
                <Box variant="layout.admin.panel.print">
                  <PersonalData.Print />
                </Box>
                <Box variant="layout.admin.panel.print">
                  <ChurchData.Print />
                </Box>
                <Box variant="layout.admin.panel.print">
                  <AddressData.Print />
                </Box>
                <Box variant="layout.admin.panel.print">
                  <GeneralInformation.Print />
                </Box>
                <Box variant="layout.admin.panel.print">
                  <ContactInformation.Print />
                </Box>
                <Box variant="layout.admin.panel.print" sx={{ mb: 4 }}>
                  <Attachments.Print attachments={attachments} />
                </Box>
                <Box
                  variant="layout.admin.panel.print"
                  sx={{ mb: 4, px: 4, mr: 4 }}>
                  <Attachments.Signature
                    member={member}
                    attachments={attachments}
                  />
                </Box>
                <Box variant="layout.admin.panel.print" sx={{ m: 0, p: 0 }}>
                  <Attachments.Others attachments={attachments} />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "20px",
                    left: 0,
                    right: 0,
                  }}>
                  <Box sx={{ fontSize: 0, textAlign: "center", mb: 2 }}>
                    Dados atualizados em:{" "}
                    {format(new Date(member.updated_at), "dd/MM/yyyy HH:mm:ss")}
                  </Box>
                  <Box
                    variant="layout.admin.panel.print"
                    sx={{ fontSize: 0, textAlign: "center" }}>
                    Todos os dados deste e cópias dos documentos respeitam as
                    exigências do novo regulamento europeu da proteção de dados.
                  </Box>
                  <Box
                    variant="layout.admin.panel.print"
                    sx={{
                      fontSize: 0,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}>
                    MSBN Portugal - Rua Almeida Garrett, 32, 2600-095 Vila
                    Franca de Xira - Portugal
                  </Box>
                </Box>
              </Box>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export const MemberPrint = (props) => {
  const id = props.id || props.match.params.id;
  const attachments = new URLSearchParams(useLocation().search)
    .get("attachments")
    ?.split(",")
    .filter((i) => i);
  const [state, setState] = useState<any>(null);
  const { data, loading } = useQuery(MEMBER_QUERY, {
    variables: { id },
  });
  useEffect(() => {
    if (data?.membro?.id) {
      const member = deserializeMember(data.membro);
      setState(member);
    }
  }, [data]);

  if (!data?.membro?.id && !loading) {
    return <Redirect to={MEMBERS_ROUTES.list} />;
  }

  if (state) {
    return <MemberPrintStatic member={state} attachments={attachments} />;
  }

  return null;
};

export default MemberPrint;
