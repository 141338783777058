"use strict";

exports.__esModule = true;
exports.USERS_QUERY = exports.DELETE_CONGREGATION_MUTATION = exports.UPDATE_CONGREGATION_MUTATION = exports.CREATE_CONGREGATION_MUTATION = exports.CONGREGATIONS_QUERY = exports.CONGREGATION_QUERY = exports.DASHBOARD_QUERY = exports.MEMBER_PDF_QUERY = exports.DELETE_MEMBER_MUTATION = exports.MEMBER_QUERY = exports.MEMBERS_QUERY = exports.UPDATE_MEMBER_MUTATION = exports.CREATE_MEMBER_MUTATION = exports.CONVERT_ATTACHMENT_TO_IMAGE = exports.DELETE_ATTACHMENT_MUTATION = exports.UPDATE_ATTACHMENT_MUTATION = exports.CREATE_ATTACHMENT_MUTATION = exports.UPLOAD_MUTATION = exports.EDUCATION_QUERY = exports.BAPTISM_METHODS_QUERY = exports.MEMBER_TYPE_QUERY = exports.CIVIL_STATUS_QUERY = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject22() {
  var data = _taggedTemplateLiteral(["\n  {\n    users {\n      id\n      username\n      email\n    }\n  }\n"]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n  mutation deleteCongregation(\n    $deleteCongregationInput: deleteCongregationInput\n  ) {\n    deleteCongregation(input: $deleteCongregationInput) {\n      congregation {\n        id\n      }\n    }\n  }\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  mutation updateCongregation(\n    $updateCongregationInput: updateCongregationInput\n  ) {\n    updateCongregation(input: $updateCongregationInput) {\n      congregation {\n        id\n      }\n    }\n  }\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  mutation createCongregation(\n    $createCongregationInput: createCongregationInput\n  ) {\n    createCongregation(input: $createCongregationInput) {\n      congregation {\n        id\n      }\n    }\n  }\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  {\n    congregations {\n      id\n      name\n    }\n  }\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  query congregation($id: ID!) {\n    congregation(id: $id) {\n      id\n      name\n    }\n  }\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  {\n    pending: membros(where: { status: \"pending\" }) {\n      id\n    }\n    members: membros(where: { memberType: { name: \"Membro\" } }) {\n      id\n    }\n    congregated: membros(where: { memberType: { name: \"Congregado\" } }) {\n      id\n    }\n    congregations {\n      id\n    }\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  query membroPdf($id: String!, $url: String!, $attachments: [String]!) {\n    membroPdf(id: $id, url: $url, attachments: $attachments) {\n      url\n    }\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  mutation deleteMembro($deleteMembroInput: deleteMembroInput) {\n    deleteMembro(input: $deleteMembroInput) {\n      membro {\n        id\n      }\n    }\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  query membro($id: ID!) {\n    membro(id: $id) {\n      id\n      name\n      dateOfBirth\n      civilStatus {\n        id\n        name\n      }\n      fatherName\n      motherName\n\n      congregation {\n        id\n        name\n      }\n      memberType {\n        id\n        name\n      }\n      baptized\n      dateOfBaptism\n      baptismMethod {\n        id\n        name\n      }\n      positionsHeld\n\n      address\n      locale\n      village\n      district\n      postalCode\n\n      identificationDocument\n      taxpayerNumber\n      occupation\n      education\n\n      contactNumber\n      email\n\n      attachments {\n        id\n        type\n        file {\n          id\n          url\n          mime\n          name\n          size\n          formats\n        }\n        crop {\n          id\n          url\n          mime\n          name\n          size\n        }\n      }\n\n      picture {\n        id\n        url\n        mime\n        name\n        size\n        formats\n      }\n\n      status\n      observations\n      created_at\n      updated_at\n    }\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  {\n    membros(sort: \"created_at:asc\") {\n      id\n      name\n      dateOfBirth\n      civilStatus {\n        id\n        name\n      }\n      fatherName\n      motherName\n\n      congregation {\n        id\n        name\n      }\n      memberType {\n        id\n        name\n      }\n      baptized\n      dateOfBaptism\n      baptismMethod {\n        id\n        name\n      }\n      positionsHeld\n\n      address\n      locale\n      village\n      district\n      postalCode\n\n      identificationDocument\n      taxpayerNumber\n      occupation\n      education\n\n      contactNumber\n      email\n\n      attachments {\n        id\n        type\n        file {\n          id\n          url\n          mime\n          name\n          size\n          formats\n        }\n      }\n\n      picture {\n        id\n        url\n        mime\n        name\n        size\n        formats\n      }\n\n      status\n      observations\n      created_at\n    }\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  mutation updateMembro($updateMembroInput: updateMembroInput) {\n    updateMembro(input: $updateMembroInput) {\n      membro {\n        id\n      }\n    }\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  mutation createMembro($createMembroInput: createMembroInput) {\n    createMembro(input: $createMembroInput) {\n      membro {\n        id\n      }\n    }\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  query convertToImage($id: ID!) {\n    convertToImage(id: $id) {\n      url\n    }\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  mutation deleteAttachment($deleteAttachmentInput: deleteAttachmentInput) {\n    deleteAttachment(input: $deleteAttachmentInput) {\n      attachment {\n        id\n      }\n    }\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  mutation updateAttachment($updateAttachmentInput: updateAttachmentInput) {\n    updateAttachment(input: $updateAttachmentInput) {\n      attachment {\n        id\n        type\n        file {\n          id\n          url\n          mime\n          name\n          size\n          formats\n        }\n        crop {\n          id\n          url\n          mime\n          name\n          size\n        }\n      }\n    }\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  mutation createAttachment($createAttachmentInput: createAttachmentInput) {\n    createAttachment(input: $createAttachmentInput) {\n      attachment {\n        id\n        type\n        file {\n          id\n          url\n          mime\n          name\n          size\n          formats\n        }\n      }\n    }\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  mutation upload($file: Upload!) {\n    upload(file: $file) {\n      url\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  {\n    educations {\n      id\n      name\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  {\n    baptismMethods {\n      id\n      name\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  {\n    memberTypes {\n      id\n      name\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  {\n    civilStatuses {\n      id\n      name\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var CIVIL_STATUS_QUERY = (0, _graphqlTag.default)(_templateObject());
exports.CIVIL_STATUS_QUERY = CIVIL_STATUS_QUERY;
var MEMBER_TYPE_QUERY = (0, _graphqlTag.default)(_templateObject2());
exports.MEMBER_TYPE_QUERY = MEMBER_TYPE_QUERY;
var BAPTISM_METHODS_QUERY = (0, _graphqlTag.default)(_templateObject3());
exports.BAPTISM_METHODS_QUERY = BAPTISM_METHODS_QUERY;
var EDUCATION_QUERY = (0, _graphqlTag.default)(_templateObject4());
exports.EDUCATION_QUERY = EDUCATION_QUERY;
var UPLOAD_MUTATION = (0, _graphqlTag.default)(_templateObject5());
exports.UPLOAD_MUTATION = UPLOAD_MUTATION;
var CREATE_ATTACHMENT_MUTATION = (0, _graphqlTag.default)(_templateObject6());
exports.CREATE_ATTACHMENT_MUTATION = CREATE_ATTACHMENT_MUTATION;
var UPDATE_ATTACHMENT_MUTATION = (0, _graphqlTag.default)(_templateObject7());
exports.UPDATE_ATTACHMENT_MUTATION = UPDATE_ATTACHMENT_MUTATION;
var DELETE_ATTACHMENT_MUTATION = (0, _graphqlTag.default)(_templateObject8());
exports.DELETE_ATTACHMENT_MUTATION = DELETE_ATTACHMENT_MUTATION;
var CONVERT_ATTACHMENT_TO_IMAGE = (0, _graphqlTag.default)(_templateObject9());
exports.CONVERT_ATTACHMENT_TO_IMAGE = CONVERT_ATTACHMENT_TO_IMAGE;
var CREATE_MEMBER_MUTATION = (0, _graphqlTag.default)(_templateObject10());
exports.CREATE_MEMBER_MUTATION = CREATE_MEMBER_MUTATION;
var UPDATE_MEMBER_MUTATION = (0, _graphqlTag.default)(_templateObject11());
exports.UPDATE_MEMBER_MUTATION = UPDATE_MEMBER_MUTATION;
var MEMBERS_QUERY = (0, _graphqlTag.default)(_templateObject12());
exports.MEMBERS_QUERY = MEMBERS_QUERY;
var MEMBER_QUERY = (0, _graphqlTag.default)(_templateObject13());
exports.MEMBER_QUERY = MEMBER_QUERY;
var DELETE_MEMBER_MUTATION = (0, _graphqlTag.default)(_templateObject14());
exports.DELETE_MEMBER_MUTATION = DELETE_MEMBER_MUTATION;
var MEMBER_PDF_QUERY = (0, _graphqlTag.default)(_templateObject15());
exports.MEMBER_PDF_QUERY = MEMBER_PDF_QUERY;
var DASHBOARD_QUERY = (0, _graphqlTag.default)(_templateObject16());
exports.DASHBOARD_QUERY = DASHBOARD_QUERY;
var CONGREGATION_QUERY = (0, _graphqlTag.default)(_templateObject17());
exports.CONGREGATION_QUERY = CONGREGATION_QUERY;
var CONGREGATIONS_QUERY = (0, _graphqlTag.default)(_templateObject18());
exports.CONGREGATIONS_QUERY = CONGREGATIONS_QUERY;
var CREATE_CONGREGATION_MUTATION = (0, _graphqlTag.default)(_templateObject19());
exports.CREATE_CONGREGATION_MUTATION = CREATE_CONGREGATION_MUTATION;
var UPDATE_CONGREGATION_MUTATION = (0, _graphqlTag.default)(_templateObject20());
exports.UPDATE_CONGREGATION_MUTATION = UPDATE_CONGREGATION_MUTATION;
var DELETE_CONGREGATION_MUTATION = (0, _graphqlTag.default)(_templateObject21());
exports.DELETE_CONGREGATION_MUTATION = DELETE_CONGREGATION_MUTATION;
var USERS_QUERY = (0, _graphqlTag.default)(_templateObject22());
exports.USERS_QUERY = USERS_QUERY;