import Logo from "assets/logo.png";
import React from "react";
import { Flex, Image, Text } from "theme-ui";

import { Logout } from "app/authentication/Logout";

export const Navbar = () => {
  return (
    <Flex variant="layout.admin.navbar">
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          width: "sidebar",
          pl: 4,
        }}>
        <Flex
          sx={{
            alignItems: "center",
          }}>
          <Image
            src={Logo}
            sx={{ width: 40, mr: 3, bg: "white", p: 1, borderRadius: 4 }}
          />
          <Text sx={{ fontSize: 3 }}>MSBN Portugal</Text>
        </Flex>
      </Flex>
      <Logout />
    </Flex>
  );
};
