export { default as MemberList } from "./MemberList";
export { default as MemberRoutes } from "./MemberRoutes";
export enum MEMBER_STATUS {
  pending = "pending",
  active = "active",
}

export enum MEMBER_STATUS_LABELS {
  pending = "Pendente",
  active = "Activo",
}
