import { useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Icon } from "@tooca-ui/icons";
import { useFormikContext, FieldArray } from "formik";
import cloneDeep from "lodash.clonedeep";
import React from "react";
import {
  Styled,
  Text,
  Label,
  Divider,
  Button,
  Flex,
  Box,
  Grid,
  Image,
  Embed,
} from "theme-ui";
import { object, array, string } from "yup";

import { currentConfig } from "@members/config";
import { SelectField } from "@members/fields";
import { FileUploadFieldInline } from "@members/fields";
import { file } from "@members/fields";
import {
  CREATE_ATTACHMENT_MUTATION,
  UPDATE_ATTACHMENT_MUTATION,
  DELETE_ATTACHMENT_MUTATION,
} from "@members/graphql";
import { SpinnerAbsolute } from "@members/layout";

import { getSignatures, isSignature } from "app/print/modal";
import { Title } from "app/utils";

const uploadPrefix = currentConfig.UPLOAD_PREFIX;

const MAX_SIZE = 1024 * 10; // 10MB;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];

const validationSchema = object().shape({
  attachments: array().of(
    object().shape({
      file: file({ MAX_SIZE, SUPPORTED_FORMATS }),
      type: string().required("Required"),
    })
  ),
});

const initialValues: any = {
  attachments: [],
};

export enum attachmentTypes {
  weddingCertificate = "weddingCertificate",
  divorceCertificate = "divorceCertificate",
  documentId = "documentId",
  digitalSignature = "digitalSignature",
  manualSignature = "manualSignature",
  others = "others",
}

export enum attachmentTypeLabels {
  others = "Outros",
  weddingCertificate = "Certificado de Casamento",
  divorceCertificate = "Certificado de Divórcio",
  documentId = "Documento de Identificação",
  digitalSignature = "Assinatura Digital",
  manualSignature = "Assinatura Digitalizada",
}

export const Attachments = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [createAttachment, { ...create }] = useMutation<any>(
    CREATE_ATTACHMENT_MUTATION
  );
  const [updateAttachment, { ...update }] = useMutation<any>(
    UPDATE_ATTACHMENT_MUTATION
  );

  const [deleteAttachment, { ...deleteData }] = useMutation<any>(
    DELETE_ATTACHMENT_MUTATION
  );

  const isLoading = create.loading || update.loading || deleteData.loading;

  const onUploadCreate = ({ index, file, type }) => {
    createAttachment({
      variables: {
        createAttachmentInput: {
          data: { type, file: file.id },
        },
      },
    })
      .then((response) => {
        const { attachments } = cloneDeep(values);
        const { attachment } = response.data.createAttachment;

        attachments[index] = {
          ...attachment,
          url: attachment.file ? `${uploadPrefix}${attachment.file.url}` : null,
          attachmentId: attachment.id,
        };

        setFieldValue("attachments", attachments);
      })
      .catch((e) => {
        Sentry.withScope(function (scope) {
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(e);
        });
      });
  };

  const onUploadUpdate = ({ id, file, type }) => {
    updateAttachment({
      variables: {
        updateAttachmentInput: { where: { id }, data: { type, file: file.id } },
      },
    })
      .then((response) => {
        const { attachment } = response.data.updateAttachment;
        const attachments = values.attachments.map((currentAttachment) => {
          // eslint-disable-next-line no-console
          if (currentAttachment.id == attachment.id) {
            return {
              ...attachment,
              url: attachment.file
                ? `${uploadPrefix}${attachment.file.url}`
                : null,
              attachmentId: currentAttachment.id,
            };
          }
          return attachment;
        });

        setFieldValue("attachments", attachments);
      })
      .catch((e) => {
        Sentry.withScope(function (scope) {
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(e);
        });
      });
  };

  const remove = ({ id }) => {
    deleteAttachment({
      variables: {
        deleteAttachmentInput: { where: { id } },
      },
    });
  };

  return (
    <>
      <Styled.h3>Anexos</Styled.h3>
      <Divider />

      <FieldArray
        name="attachments"
        render={(arrayHelpers) => (
          <fieldset
            disabled={isLoading}
            style={{ border: "none", padding: 0, position: "relative" }}>
            {isLoading && <SpinnerAbsolute />}
            {values.attachments &&
              values.attachments.length > 0 &&
              values.attachments.map((attachment, index) => {
                return (
                  <div key={index}>
                    <SelectField
                      label="Tipo de Documento"
                      name={`attachments.${index}.type`}
                      required>
                      {Object.keys(attachmentTypes).map((attachmentType) => (
                        <option value={attachmentType} key={attachmentType}>
                          {attachmentTypeLabels[attachmentType]}
                        </option>
                      ))}
                    </SelectField>
                    {attachment.type && (
                      <FileUploadFieldInline
                        label="Ficheiro"
                        name={`attachments.${index}.file`}
                        maxSize={MAX_SIZE * 1024}
                        accept={SUPPORTED_FORMATS}
                        onUpload={(file) => {
                          if (attachment.id) {
                            onUploadUpdate({
                              id: attachment.id,
                              file,
                              type: attachment.type,
                            });
                          } else {
                            onUploadCreate({
                              index,
                              file,
                              type: attachment.type,
                            });
                          }
                        }}
                        onRemove={() => {
                          arrayHelpers.remove(index);
                          if (attachment.id) {
                            remove(attachment);
                          }
                        }}
                        required
                      />
                    )}
                    {create.error || update.error ? (
                      <Label variant="label.error">
                        Erro ao tentar fazer o upload, tente novamente
                      </Label>
                    ) : null}

                    <Divider />
                  </div>
                );
              })}

            <Flex sx={{ justifyContent: "flex-end" }}>
              <Button type="button" onClick={() => arrayHelpers.push("")}>
                <Flex sx={{ alignItems: "center" }}>
                  <Icon name="Add" size="12" />
                  <Text ml="2">Adicionar anexo</Text>
                </Flex>
              </Button>
            </Flex>
          </fieldset>
        )}
      />

      <Text>
        <small>
          <b>Tamanho máximo: </b> 10MB
        </small>
      </Text>
      <Text>
        <small>
          <b>Formatos suportados: </b> jpg, jpeg, png ou pdf
        </small>
      </Text>
    </>
  );
};

export const AttachmentsPrint = ({ attachments }) => {
  const { values } = useFormikContext<any>();

  if (!attachments || (attachments && attachments.length === 0)) {
    return null;
  }

  const filteredAttachments = [...values.attachments].filter((attachment) => {
    return (
      attachments &&
      attachments.includes(attachment.id) &&
      !isSignature(attachment)
    );
  });

  return (
    <>
      <Title>Anexos</Title>
      <Box px={2}>
        {filteredAttachments.map((attachment, index) => {
          return (
            <Box mb={1} sx={{ display: "inline" }} key={attachment.id}>
              {index > 0 ? ", " : ""}
              {attachmentTypeLabels[attachment.type]}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export const SignaturePrint = ({ member, attachments }) => {
  const signature = getSignatures(member.attachments).filter(
    (attachment) => attachments && attachments.includes(attachment.id)
  );

  return signature.length > 0 ? (
    <Grid
      sx={{
        gridTemplateColumns: "1fr",
      }}>
      <Flex
        sx={{
          gridColumn: 1,
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "flex-end",
        }}>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Label variant="label.small" sx={{ justifyContent: "center" }}>
            Assinatura
          </Label>
          <Flex
            sx={{
              border: "1px solid gray",
              width: "200px",
              overflow: "hidden",
            }}>
            <Image
              src={signature[0]?.crop?.url}
              sx={{
                objectFit: "contain",
                objectPosition: "top",
                height: "auto",
                width: "100%",
                p: 0,
              }}
            />
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Label variant="label.small" sx={{ justifyContent: "center" }}>
              ({member.name})
            </Label>
          </Flex>
        </Flex>
      </Flex>
    </Grid>
  ) : null;
};

export const OthersPrint = ({ attachments }) => {
  const { values } = useFormikContext<any>();

  if (!attachments || (attachments && attachments.length === 0)) {
    return null;
  }

  const filteredAttachments = [...values.attachments].filter((attachment) => {
    return (
      attachments.includes(attachment.id) &&
      !isSignature(attachment) &&
      attachment.file.mime !== "application/pdf"
    );
  });

  return (
    <>
      {filteredAttachments &&
        filteredAttachments.map((attachment) => (
          <Grid
            key={attachment.id}
            sx={{
              gridTemplateColumns: "1fr",
              height: "100vh",
              width: "100%",
              pageBreakBefore: "always",
              pageBreakAfter: "always",
              py: 4,
              px: 2,
            }}>
            <embed
              title={attachment.id}
              src={attachment.file.url}
              style={{
                objectFit: "contain",
                objectPosition: "top",
                width: "100%",
                height: "100%",
              }}
            />
          </Grid>
        ))}
    </>
  );
};

export default {
  Page: Attachments,
  Print: AttachmentsPrint,
  Signature: SignaturePrint,
  Others: OthersPrint,
  initialValues,
  validationSchema,
};
