import { useQuery } from "@apollo/react-hooks";
import { Tooltip } from "@chakra-ui/core";
import { Icon } from "@tooca-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, Button, Box, Badge, Divider } from "theme-ui";

import {
  MEMBERS_QUERY,
  CONGREGATIONS_QUERY,
  DASHBOARD_QUERY,
} from "@members/graphql";
import { GridLayout, Area, SpinnerAbsolute } from "@members/layout";

const Block = ({ sx = {}, ...props }) => (
  <Flex
    variant="layout.admin.panel"
    sx={{
      bg: "white",
      p: 4,
      m: 0,
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",
      ...sx,
    }}
    {...props}
  />
);

const Title = ({ sx = {}, ...props }) => (
  <Text
    variant="styles.h2"
    sx={{ mt: 0, mb: 3, textAlign: "center", ...sx }}
    {...props}
  />
);

const Number = ({ sx = {}, ...props }) => (
  <Badge
    variant="rounded"
    sx={{
      m: 0,
      fontSize: 5,
      bg: "primary",
      color: "white",
      width: "80px",
      height: "80px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ml: "4",
      ...sx,
    }}
    {...props}
  />
);

export const Dashboard = () => {
  const { loading, data } = useQuery<any>(DASHBOARD_QUERY);

  return (
    <Box sx={{ height: "100%" }}>
      {loading && <SpinnerAbsolute />}
      <Flex
        variant="layout.admin.panel"
        sx={{
          justifyContent: "space-between",
        }}>
        <Title>Dashboard</Title>
      </Flex>
      {data && (
        <GridLayout>
          <Block>
            <Title>Membros</Title>
            <Number sx={{ bg: "success" }}>{data.members.length}</Number>
          </Block>
          <Block>
            <Title>Congregados</Title>
            <Number>{data.congregated.length}</Number>
          </Block>
          <Block>
            <Title>Pendentes</Title>
            <Number sx={{ bg: "warning" }}>{data.pending.length}</Number>
          </Block>
          <Block>
            <Title>Congregações</Title>
            <Number sx={{ bg: "gray" }}>{data.congregations.length}</Number>
          </Block>
        </GridLayout>
      )}
    </Box>
  );
};

export default Dashboard;
