import React from "react";
import { Box, Styled, Divider } from "theme-ui";

import { TextField } from "@members/fields";

export const CongregationForm = ({ isDisabled = false }) => {
  return (
    <>
      <Box variant="layout.admin.panel">
        <Styled.h3>Congregação</Styled.h3>
        <Divider />

        <TextField label="Nome" name="name" required />
      </Box>
    </>
  );
};
