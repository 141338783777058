import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Formik, Form } from "formik";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Box, Flex, Text, Button, Label } from "theme-ui";

import {
  CONGREGATION_QUERY,
  DELETE_CONGREGATION_MUTATION,
  UPDATE_CONGREGATION_MUTATION,
} from "@members/graphql";
import { SpinnerAbsolute } from "@members/layout";

import { CongregationDelete } from "./CongregationDelete";
import { CongregationForm } from "./CongregationForm";
import { CONGREGATION_ROUTES } from "./CongregationRoutes";
import { validationSchema, options } from "./CongregationUtils";
import { useRedirect, useAlertSuccess } from "app/utils";

export const CongregationView = (props) => {
  const id = props.match.params.id;
  const setRedirect = useRedirect();
  const [state, setState] = useState<any>({});
  const [edit] = useState(true);
  const { data, loading, refetch } = useQuery(CONGREGATION_QUERY, {
    variables: { id },
  });

  const [upadateCongregation, { ...update }] = useMutation(
    UPDATE_CONGREGATION_MUTATION,
    options
  );

  const isDisabled = !edit || loading || update.loading;
  const alertSuccess = useAlertSuccess();

  useEffect(() => {
    if (data?.congregation?.id) {
      setState(data.congregation);
    }
  }, [data]);

  const updateCongregationHandler = ({ name }) => {
    upadateCongregation({
      variables: { updateCongregationInput: { where: { id }, data: { name } } },
    })
      .then(() => {
        refetch({ id });
        alertSuccess("Registo autalizado com sucesso.");
        setRedirect(CONGREGATION_ROUTES.list);
      })
      .catch((e) => {
        Sentry.withScope(function (scope) {
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(e);
        });
      });
  };

  if (!data?.congregation?.id && !loading) {
    return <Redirect to={CONGREGATION_ROUTES.list} />;
  }

  return (
    <>
      {loading && <SpinnerAbsolute />}
      {state?.id && (
        <>
          <Flex
            variant="layout.admin.panel"
            sx={{
              justifyContent: "space-between",
              position: "sticky",
              top: 0,
              zIndex: 2,
            }}>
            <Text variant="styles.h2" sx={{ m: 0 }}>
              {state.name}
            </Text>
          </Flex>

          <Formik
            validateOnMount={false}
            initialValues={state}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              updateCongregationHandler(values);
            }}>
            {({ isValid }) => {
              return (
                <Form autoComplete="off" noValidate={true}>
                  <fieldset
                    disabled={isDisabled}
                    style={{ border: "none", padding: 0 }}>
                    <CongregationForm isDisabled={isDisabled} />
                    <Box
                      sx={{
                        position: "fixed",
                        bottom: "50px",
                        left: "276px",
                        right: "26px",
                      }}>
                      <Flex
                        variant="layout.admin.panel"
                        sx={{
                          mb: 0,
                          bg: "white",
                          width: "100%",
                          p: 3,
                          justifyContent: "flex-end",
                        }}>
                        {update.loading && <SpinnerAbsolute />}
                        <Box>
                          {update.error && (
                            <Label
                              variant="label.error"
                              sx={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              Erro ao guardar, tente novamente...
                            </Label>
                          )}
                        </Box>
                        <Box>
                          <CongregationDelete
                            sx={{ mr: 3 }}
                            disabled={isDisabled}
                            mutation={DELETE_CONGREGATION_MUTATION}
                            variables={{
                              deleteCongregationInput: {
                                where: { id },
                              },
                            }}
                            onDelete={() =>
                              setRedirect(CONGREGATION_ROUTES.list)
                            }>
                            Remover
                          </CongregationDelete>
                          <Button
                            type="submit"
                            variant="primary"
                            disabled={!isValid || isDisabled}>
                            Guardar
                          </Button>
                        </Box>
                      </Flex>
                    </Box>
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};

export default CongregationView;
