import { useFormikContext } from "formik";
import React from "react";
import { Flex, Divider, Text } from "theme-ui";
import { object, string } from "yup";

import { SwitchField, TextAreaField } from "@members/fields";
import { Status } from "@members/layout";

import { MEMBER_STATUS_LABELS } from "app/members";

const validationSchema = object().shape({
  status: string(),
  observations: string().nullable(),
});

const initialValues: any = {
  status: false,
  observations: "",
};

export const StatusData = ({ isDisabled }) => {
  const { values } = useFormikContext<any>();
  return (
    <>
      <Flex
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}>
        <Status
          sx={{
            bg: values.status ? "success" : "warning",
            width: "30px",
            height: "30px",
          }}
        />
        <Text variant="styles.h3" sx={{ mx: 2, my: 0 }}>
          Estado do registo
        </Text>
        <SwitchField
          disabled={isDisabled}
          name="status"
          label={
            values.status
              ? MEMBER_STATUS_LABELS.active
              : MEMBER_STATUS_LABELS.pending
          }
        />
      </Flex>
      <Divider />
      <TextAreaField label="Observações" name="observations" rows={4} />
    </>
  );
};

export default { Page: StatusData, initialValues, validationSchema };
