import { useQuery } from "@apollo/react-hooks";
import { Icon } from "@tooca-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, Button } from "theme-ui";

import { CONGREGATIONS_QUERY } from "@members/graphql";
import { SpinnerAbsolute, Table } from "@members/layout";

import { CONGREGATION_ROUTES } from "./CongregationRoutes";
import { useRedirect } from "app/utils";

export const CongregationList = () => {
  const history = useHistory();
  const setRedirect = useRedirect();
  const { data, loading } = useQuery(CONGREGATIONS_QUERY);
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "congregations",
        columns: [
          {
            Header: "Name",
            accessor: "name",
          },
        ],
      },
    ],
    []
  );
  return (
    <>
      <Flex
        variant="layout.admin.panel"
        sx={{
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}>
        <Text variant="styles.h2" sx={{ m: 0 }}>
          Congregações
        </Text>
        <Button
          onClick={() => setRedirect(CONGREGATION_ROUTES.add)}
          variant="success">
          <Flex sx={{ alignItems: "center" }}>
            <Icon name="Add" size={15} />
            <Text ml={2}>Adicionar</Text>
          </Flex>
        </Button>
      </Flex>
      {loading && <SpinnerAbsolute />}
      {data?.congregations && (
        <Table.TableUi
          columns={columns}
          data={data.congregations}
          clickedRow={true}
          onClick={(id) => history.push(`/congregacoes/${id}`)}
        />
      )}
    </>
  );
};

export default CongregationList;
