import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { Box, Styled, Divider, Grid, Text, Flex, Label } from "theme-ui";
import { object, string, boolean, mixed } from "yup";

import {
  DateField,
  SelectField,
  RadioField,
  CheckField,
  TextAreaField,
} from "@members/fields";
import { date } from "@members/fields";
import {
  CONGREGATIONS_QUERY,
  MEMBER_TYPE_QUERY,
  BAPTISM_METHODS_QUERY,
} from "@members/graphql";
import { useQuerySelectOptions, useQueryRadioOptions } from "@members/hooks";

import { Title } from "app/utils";

const validationSchema = object().shape({
  memberType: string().required("Obrigatório"),
  congregation: string().required("Obrigatório"),
  baptized: boolean(),
  dateOfBaptism: mixed().when("baptized", {
    is: true,
    then: date(),
    otherwise: string().nullable(),
  }),
  baptismMethod: string().when("baptized", {
    is: true,
    then: string().required("Obrigatório"),
    otherwise: string().nullable(),
  }),
  positionsHeld: string(),
});

const initialValues: any = {
  memberType: "",
  congregation: "",
  baptized: false,
  dateOfBaptism: null,
  baptismMethod: null,
  positionsHeld: "",
};

export const ChurchData = () => {
  const memberTypes = useQueryRadioOptions(MEMBER_TYPE_QUERY, "memberTypes");
  const baptismMethods = useQueryRadioOptions(
    BAPTISM_METHODS_QUERY,
    "baptismMethods"
  );
  const congregations = useQuerySelectOptions(
    CONGREGATIONS_QUERY,
    "congregations"
  );
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();

  useEffect(() => {
    if (!values.baptized) {
      setFieldValue("dateOfBaptism", null);
      setFieldValue("baptismMethod", null);
      setFieldTouched("baptismMethod", false);
      setFieldTouched("dateOfBaptism", false);
    }
  }, [values.baptized, setFieldValue, setFieldTouched]);

  return (
    <>
      <Styled.h3>Dados congregacionais</Styled.h3>
      <Divider />

      <SelectField label="Congregação" name="congregation" required>
        {congregations.options}
      </SelectField>
      <RadioField label="" name="memberType" options={memberTypes.options} />
      <Divider />
      <CheckField label="É Batizado em águas?" name="baptized" />
      {values?.baptized && (
        <Grid
          sx={{
            gridTemplateColumns: ["1fr", "1fr 1fr"],
            gap: [0, null, 4],
          }}>
          <Box sx={{ gridColumn: 1 }}>
            <DateField label="Data do Batismo" name="dateOfBaptism" required />
          </Box>
          <Box sx={{ gridColumn: [1, null, 2] }}>
            <RadioField
              label="Forma que foi batizado"
              name="baptismMethod"
              options={baptismMethods.options}
              required
            />
          </Box>
        </Grid>
      )}
      <Divider />
      <TextAreaField
        label="Funções que ocupa na igreja MSBN"
        name="positionsHeld"
        rows={4}
      />
    </>
  );
};

export const ChurchDataPrint = () => {
  const memberTypes = useQueryRadioOptions(MEMBER_TYPE_QUERY, "memberTypes");
  const baptismMethods = useQueryRadioOptions(
    BAPTISM_METHODS_QUERY,
    "baptismMethods"
  );
  const congregations = useQueryRadioOptions(
    CONGREGATIONS_QUERY,
    "congregations"
  );
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();

  useEffect(() => {
    if (!values.baptized) {
      setFieldValue("dateOfBaptism", null);
      setFieldValue("baptismMethod", null);
      setFieldTouched("baptismMethod", false);
      setFieldTouched("dateOfBaptism", false);
    }
  }, [values.baptized, setFieldValue, setFieldTouched]);

  const baptismMethod = baptismMethods?.options?.find(
    (i) => i.id == values.baptismMethod
  );

  const congregation = congregations?.options?.find(
    (i) => i.id == values.congregation
  );

  const memberType = memberTypes?.options?.find(
    (i) => i.id == values.memberType
  );

  return (
    <>
      <Title>Dados congregacionais</Title>
      <Grid
        sx={{
          gridTemplateColumns: "1fr 1fr 160px",
          rowGap: 2,
          columnGap: 2,
          mb: 2,
          px: 2,
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <Label mb={1} variant="label.small">
            Congregação
          </Label>
          {congregation?.label}
        </Box>
        <Box sx={{ gridColumn: 2 }}>
          <Label mb={1} variant="label.small">
            Membro ou Congregado?
          </Label>
          {memberType?.label}
        </Box>
      </Grid>

      <Grid
        sx={{
          gridTemplateColumns: "1fr 1fr 160px",
          rowGap: 2,
          columnGap: 2,
          mb: 2,
          px: 2,
        }}>
        <Box>
          <Label mb={1} variant="label.small">
            É Batizado em águas?
          </Label>
          {values?.baptized ? "Sim" : "Não"}
        </Box>
        {values?.baptized && (
          <Box>
            <Label mb={1} variant="label.small">
              Forma que foi batizado
            </Label>
            {baptismMethod?.label}
          </Box>
        )}
        {values?.baptized && (
          <Box>
            <Label mb={1} variant="label.small">
              Data do Batismo
            </Label>
            {values.dateOfBaptism}
          </Box>
        )}
      </Grid>
      {values.positionsHeld && (
        <Box sx={{ mb: 2, px: 2 }}>
          <Label mb={1} variant="label.small">
            Funções que ocupa na igreja MSBN
          </Label>
          <Box sx={{ fontSize: 1, whiteSpace: "pre-line" }}>
            {values.positionsHeld.split("\n").join(", ")}
          </Box>
        </Box>
      )}
    </>
  );
};

export default {
  Page: ChurchData,
  Print: ChurchDataPrint,
  initialValues,
  validationSchema,
};
