import { useToast } from "@chakra-ui/core";
import { lighten } from "@theme-ui/color";
import { Icon } from "@tooca-ui/icons";
import React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, Heading, Box } from "theme-ui";


export const useRedirect = () => {
  const [redirect, setRedirect] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (redirect) {
      history.push(redirect);
    }
  }, [history, redirect]);

  return setRedirect;
};

export const useAlert = ({ sx = {}, children }) => {
  const toast = useToast();

  const show = (text) =>
    toast({
      position: "bottom",
      render: () => (
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            p: 3,
            m: 3,
            ...sx,
          }}>
          {children(text)}
        </Flex>
      ),
    });

  return show;
};

export const useAlertSuccess = () => {
  const show = useAlert({
    sx: { bg: "success" },
    children: (text) => (
      <>
        <Icon name="Correct" />
        <Text ml={2}>{text}</Text>
      </>
    ),
  });

  return show;
};

export const Title = ({ children, sx = {}, ...props }) => (
  <Box
    sx={{
      bg: lighten("primary", 0.5),
      px: 2,
      mb: 2,
      color: "text",
      borderRadius: 1,
      ...sx,
    }}
    {...props}>
    <Heading as="h5">{children}</Heading>
  </Box>
);
