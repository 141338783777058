import { Icon } from "@tooca-ui/icons";
import React from "react";
import { Redirect } from "react-router";
import { Box, Flex } from "theme-ui";


import { AUTHENTICATION_ROUTES } from "./AuthenticationRoutes";
import { useAuthentication } from "./hooks";

export const Logout = () => {
  const { logout, isAuthenticated } = useAuthentication();

  if (!isAuthenticated) {
    return <Redirect to={AUTHENTICATION_ROUTES.view} />;
  }

  return (
    <Flex
      sx={{
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={logout}>
      <Box mr={2}>Sair</Box> <Icon name="Logout" />
    </Flex>
  );
};
