import { useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Icon } from "@tooca-ui/icons";
import { Formik, Form } from "formik";
import React, { useEffect } from "react";
import { Box, Flex, Text, Button, Label } from "theme-ui";

import { CREATE_MEMBER_MUTATION } from "@members/graphql";
import { SpinnerAbsolute } from "@members/layout";

import { MemberForm } from "./MemberForm";
import { MEMBERS_ROUTES } from "./MemberRoutes";
import {
  validationSchema,
  options,
  initialValues,
  serializeMember,
} from "./MemberUtils";
import { useRedirect, useAlertSuccess } from "app/utils";

const initialValuesFilled = {
  name: "Name",
  dateOfBirth: "01/02/2001",
  civilStatus: "1",
  fatherName: "Father Name",
  motherName: "Mother Name",
  picture: {
    formats: {
      thumbnail: {
        ext: ".jpeg",
        hash: "thumbnail_0_99755e75ca",
        height: 153,
        mime: "image/jpeg",
        size: 3.81,
        url: "http://localhost:1337/uploads/thumbnail_0_99755e75ca.jpeg",
        width: 245,
      },
    },
    id: 320,
    mime: "image/jpeg",
    name: "0",
    size: 95.88,
    url: "http://localhost:1337/uploads/0_8c848b2d4a.jpeg",
  },

  memberType: "1",
  congregation: "1",
  baptized: false,
  dateOfBaptism: null,
  baptismMethod: null,
  positionsHeld: "Positions",

  address: "address",
  locale: "locale",
  village: "village",
  district: "district",
  postalCode: "1000-100",

  identificationDocument: "KAJSDLKADJ",
  taxpayerNumber: "123123123",
  occupation: "Ocupation",
  education: "8",

  contactNumber: "123123123",
  email: "dilvanezanardine@gmail.com",

  documentType: "others",
  docs: {
    attachmentId: "148",
    formats: null,
    id: 323,
    mime: "application/pdf",
    name: "blank",
    size: 4.91,
    url: "http://localhost:1337/uploads/blank_c4ef57b412.pdf",
  },
};

export const MemberAdd = (props) => {
  const setRedirect = useRedirect();
  const [createMembro, { loading, data, error }] = useMutation(
    CREATE_MEMBER_MUTATION,
    options
  );
  const alertSuccess = useAlertSuccess();

  const createMembroHandler = (data) => {
    const member = serializeMember(data);

    createMembro({
      variables: { createMembroInput: { data: member } },
    }).catch((e) => {
      Sentry.withScope(function (scope) {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    });
  };

  useEffect(() => {
    if (data?.createMembro.membro.id) {
      setRedirect(MEMBERS_ROUTES.list);
      alertSuccess("Registo adicionado com sucesso");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Flex
        variant="layout.admin.panel"
        sx={{
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}>
        <Text variant="styles.h2" sx={{ m: 0 }}>
          Novo Membro
        </Text>
      </Flex>

      <Formik
        validateOnMount={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          createMembroHandler(values);
        }}>
        {({ isValid }) => {
          return (
            <Form autoComplete="off" noValidate={true}>
              <fieldset
                disabled={loading}
                style={{ border: "none", padding: 0 }}>
                <MemberForm />
                <Box
                  sx={{
                    position: "fixed",
                    bottom: "50px",
                    left: "276px",
                    right: "26px",
                  }}>
                  {loading && <SpinnerAbsolute />}
                  <Flex
                    variant="layout.admin.panel"
                    sx={{
                      mb: 0,
                      bg: "white",
                      width: "100%",
                      p: 3,
                      justifyContent: "space-between",
                    }}>
                    <Box>
                      {error && (
                        <Label
                          variant="label.error"
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          Erro ao guardar, tente novamente...
                        </Label>
                      )}
                    </Box>
                    <Box>
                      <Button
                        variant="outline"
                        mr={2}
                        onClick={() => setRedirect(MEMBERS_ROUTES.list)}>
                        <Flex sx={{ alignItems: "center" }}>
                          <Icon name="Close" size={15} color="danger" />
                          <Text ml={2}>Cancelar</Text>
                        </Flex>
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={!isValid || loading}>
                        Guardar
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default MemberAdd;
