import React from "react";
import { Switch, Route } from "react-router-dom";

import { UserList } from "./UserList";

export enum USERS_ROUTES {
  list = `/utilizadores`,
}

export default () => (
  <Switch>
    <Route exact path={USERS_ROUTES.list} component={UserList} />
  </Switch>
);
