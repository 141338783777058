import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import { App } from "app";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://aa6a7ce592024532b163ec64299795eb@o291221.ingest.sentry.io/5199143",
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
