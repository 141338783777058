import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Formik, Form } from "formik";
import isEqual from "lodash/isEqual";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Box, Flex, Text, Button, Label } from "theme-ui";

import {
  MEMBER_QUERY,
  DELETE_MEMBER_MUTATION,
  UPDATE_MEMBER_MUTATION,
  MEMBER_PDF_QUERY,
} from "@members/graphql";
import { SpinnerAbsolute } from "@members/layout";

import { MemberDelete } from "./MemberDelete";
import { MemberForm } from "./MemberForm";
import { MEMBERS_ROUTES } from "./MemberRoutes";
import {
  validationSchema,
  options,
  deserializeMember,
  serializeMember,
} from "./MemberUtils";
import { PrintPdf } from "app/print";
import { useRedirect, useAlertSuccess } from "app/utils";

export const MemberView = (props) => {
  const id = props.match.params.id;
  const setRedirect = useRedirect();
  const [state, setState] = useState<any>({});
  const [edit] = useState(true);
  const { data, loading, refetch } = useQuery(MEMBER_QUERY, {
    variables: { id },
  });

  const [upadateMembro, { ...update }] = useMutation(
    UPDATE_MEMBER_MUTATION,
    options
  );
  const isDisabled = !edit || loading || update.loading;
  const alertSuccess = useAlertSuccess();

  useEffect(() => {
    if (data?.membro?.id) {
      const member = deserializeMember(data.membro);
      setState(member);
    }
  }, [data]);

  const updateMembroHandler = (data) => {
    const values = serializeMember(data);

    upadateMembro({
      variables: { updateMembroInput: { where: { id }, data: values } },
    })
      .then(() => {
        refetch({ id });
        alertSuccess("Registo autalizado com sucesso.");
      })
      .catch((e) => {
        Sentry.withScope(function (scope) {
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(e);
        });
      });
  };

  if (!data?.membro?.id && !loading) {
    return <Redirect to={MEMBERS_ROUTES.list} />;
  }

  return (
    <>
      {loading && <SpinnerAbsolute />}
      {state?.id && (
        <>
          <Flex
            variant="layout.admin.panel"
            sx={{
              justifyContent: "space-between",
              position: "sticky",
              top: 0,
              zIndex: 2,
            }}>
            <Text variant="styles.h2" sx={{ m: 0 }}>
              {state.name}
            </Text>
            {/* <Button variant="outline" onClick={() => setEdit(!edit)}>
              <Flex sx={{ alignItems: "center" }}>
                {!edit && (
                  <>
                    <Icon name="Edit" size={20} />
                    <Text ml={2}>Editar</Text>
                  </>
                )}
                {edit && (
                  <>
                    <Icon name="Close" size={15} color="danger" />
                    <Text ml={2}>Cancelar Alterações</Text>
                  </>
                )}
              </Flex>
            </Button> */}
          </Flex>

          <Formik
            validateOnMount={false}
            initialValues={state}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              updateMembroHandler(values);
            }}>
            {({ isValid, values }) => {
              return (
                <Form autoComplete="off" noValidate={true}>
                  <fieldset
                    disabled={isDisabled}
                    style={{ border: "none", padding: 0 }}>
                    <MemberForm isDisabled={isDisabled} />
                    <Box
                      sx={{
                        position: "fixed",
                        bottom: "50px",
                        left: "276px",
                        right: "26px",
                      }}>
                      <Flex
                        variant="layout.admin.panel"
                        sx={{
                          mb: 0,
                          bg: "white",
                          width: "100%",
                          p: 3,
                          justifyContent: "flex-end",
                        }}>
                        {update.loading && <SpinnerAbsolute />}
                        <Box>
                          {update.error && (
                            <Label
                              variant="label.error"
                              sx={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              Erro ao guardar, tente novamente...
                            </Label>
                          )}
                        </Box>
                        <Box>
                          <Flex sx={{ alignItems: "center" }}>
                            {state.id && (
                              <PrintPdf
                                member={state}
                                query={MEMBER_PDF_QUERY}
                                disabled={!isValid}
                              />
                            )}

                            <MemberDelete
                              sx={{ mr: 3 }}
                              disabled={isDisabled}
                              mutation={DELETE_MEMBER_MUTATION}
                              variables={{
                                deleteMembroInput: {
                                  where: { id },
                                },
                              }}
                              onDelete={() => setRedirect(MEMBERS_ROUTES.list)}>
                              Remover
                            </MemberDelete>
                            <Button
                              type="submit"
                              variant="primary"
                              disabled={!isValid || isDisabled}>
                              Guardar
                            </Button>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};

export default MemberView;
