"use strict";

exports.__esModule = true;
exports.default = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _themeGet = _interopRequireDefault(require("@styled-system/theme-get"));

var _Area = _interopRequireDefault(require("./Area"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: 100vh;\n  grid-template-columns: ", " 1fr;\n  grid-template-rows: ", " 1fr ", ";\n  grid-template-areas:\n    \"navbar navbar\"\n    \"sidebar content\"\n    \"footer footer\";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var MainLayout = (0, _styled.default)(_Area.default)(_templateObject(), (0, _themeGet.default)("sizes.sidebar"), (0, _themeGet.default)("sizes.navbar"), (0, _themeGet.default)("sizes.footer"));
var _default = MainLayout;
exports.default = _default;