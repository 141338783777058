import { useQuery } from "@apollo/react-hooks";
import { Tooltip } from "@chakra-ui/core";
import { Icon } from "@tooca-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, Button } from "theme-ui";

import { MEMBERS_QUERY } from "@members/graphql";
import { SpinnerAbsolute, Table, Status, Link } from "@members/layout";
import { FormatDate } from "@members/utils";

import { MEMBERS_ROUTES } from "./MemberRoutes";
import { useRedirect } from "app/utils";

export const MemberList = () => {
  const history = useHistory();
  const setRedirect = useRedirect();
  const { data, loading } = useQuery(MEMBERS_QUERY);
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "membros",
        columns: [
          {
            Header: "Name",
            accessor: "name",
            enableFilters: true,
          },
          {
            Header: "Congregação",
            accessor: "congregation.name",
            enableFilters: true,
          },
          {
            Header: "Tipo",
            accessor: "memberType.name",
            enableFilters: true,
          },
          {
            Header: () => (
              <Text sx={{ textAlign: "center" }}>Registado em</Text>
            ),
            id: "created_at",
            accessor: "created_at",
            Cell: ({ row }) => (
              <Flex sx={{ justifyContent: "center" }}>
                <FormatDate
                  value={row.original.created_at}
                  format="dd/MM/yyyy"
                />
              </Flex>
            ),
          },
          {
            Header: () => <Text sx={{ textAlign: "center" }}>Estado</Text>,
            id: "status",
            accessor: "status",
            Cell: ({ row }) => {
              return (
                <Flex sx={{ justifyContent: "center" }}>
                  {row.original.status === "pending" ? (
                    <Tooltip
                      label="Pendente"
                      placement="top"
                      aria-label="Pendente"
                      hasArrow>
                      <div>
                        <Status sx={{ bg: "warning", color: "white" }} />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      label="Ativado"
                      placement="top"
                      aria-label="Ativado"
                      hasArrow>
                      <div>
                        <Status sx={{ bg: "success", color: "white" }} />
                      </div>
                    </Tooltip>
                  )}
                </Flex>
              );
            },
          },
          {
            Header: "",
            id: "view",
            Cell: ({ row }) => (
              <Flex sx={{ justifyContent: "center" }}>
                <Tooltip
                  label="Ver dados"
                  placement="top"
                  aria-label="Ver dados"
                  hasArrow>
                  <Link to={`/membros/${row.original.id}`}>
                    <Icon name="View" />
                  </Link>
                </Tooltip>
              </Flex>
            ),
          },
        ],
      },
    ],
    []
  );
  return (
    <>
      <Flex
        variant="layout.admin.panel"
        sx={{
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}>
        <Text variant="styles.h2" sx={{ m: 0 }}>
          Membros e Congregados
        </Text>
        <Button
          onClick={() => setRedirect(MEMBERS_ROUTES.add)}
          variant="success">
          <Flex sx={{ alignItems: "center" }}>
            <Icon name="Add" size={15} />
            <Text ml={2}>Adicionar</Text>
          </Flex>
        </Button>
      </Flex>
      {loading && <SpinnerAbsolute />}
      {data?.membros && (
        <Table.TableUi
          columns={columns}
          data={data.membros}
          clickedRow={true}
          onClick={(id) => history.push(`/membros/${id}`)}
        />
      )}
    </>
  );
};

export default MemberList;
