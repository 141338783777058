interface GenerateUrlToPrintProps {
  host: string;
  redirect: string;
  token: string;
}

export const generateUrlToPrint = ({
  host,
  redirect,
  token,
}: GenerateUrlToPrintProps): string => {
  const tokenString = `token=${token}`;
  return `${host}/authentication/login?${redirect}&${tokenString}`;
};
