import { useFormikContext } from "formik";
import React from "react";
import { Styled, Divider, Grid, Box, Label } from "theme-ui";
import { object, string } from "yup";

import { TextField } from "@members/fields";

import { Title } from "app/utils";

const validationSchema = object().shape({
  contactNumber: string().required("Obrigatório"),
  email: string().email("Email inválido").required("Obrigatório"),
});

const initialValues: any = {
  contactNumber: "",
  email: "",
};

export const ContactInformation = () => {
  return (
    <>
      <Styled.h3>Informações de contato</Styled.h3>
      <Divider />

      <TextField
        label="Número de Contato"
        name="contactNumber"
        mask="99999999999999999999"
        maskPlaceholder={null}
        type="tel"
        required
      />
      <TextField label="Email" name="email" type="email" required />
    </>
  );
};

export const ContactInformationPrint = () => {
  const { values } = useFormikContext<any>();

  return (
    <>
      <Title>Informações de contato</Title>
      <Grid
        sx={{
          gridTemplateColumns: "1fr 1fr 160px",
          rowGap: 2,
          columnGap: 2,
          mb: 2,
          px: 2,
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <Label mb={1} variant="label.small">
            Número de Contato
          </Label>
          {values.contactNumber}
        </Box>
        <Box sx={{ gridColumn: 2 }}>
          <Label mb={1} variant="label.small">
            Email
          </Label>
          {values.email}
        </Box>
      </Grid>
    </>
  );
};

export default {
  Page: ContactInformation,
  Print: ContactInformationPrint,
  initialValues,
  validationSchema,
};
