import React from "react";
import { Switch, Route } from "react-router-dom";

import { MemberAdd } from "./MemberAdd";
import { MemberList } from "./MemberList";
import { MemberView } from "./MemberView";

export enum MEMBERS_ROUTES {
  list = `/membros`,
  edit = `/membros/:id`,
  add = `/membros/novo`,
  print = `/membros/:id/print`,
}

export default () => (
  <Switch>
    <Route exact path={MEMBERS_ROUTES.list} component={MemberList} />
    <Route exact path={MEMBERS_ROUTES.add} component={MemberAdd} />
    <Route exact path={MEMBERS_ROUTES.edit} component={MemberView} />
  </Switch>
);
