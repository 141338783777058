import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  theme,
  useDisclosure,
} from "@chakra-ui/core";
import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import {
  Styled,
  Button,
  ThemeProvider,
  Box,
  Flex,
  Text,
  Label,
} from "theme-ui";
import { array, object, string } from "yup";

import "cropperjs/dist/cropper.css";
import { currentConfig } from "@members/config";
import { CheckField, RadioField } from "@members/fields";
import {
  MEMBER_PDF_QUERY,
  UPDATE_ATTACHMENT_MUTATION,
  CONVERT_ATTACHMENT_TO_IMAGE,
} from "@members/graphql";
import { useUploadFile } from "@members/hooks";
import { SpinnerAbsolute } from "@members/layout";
import { theme as defaultTheme } from "@members/themes";

import { useGeneratePdf } from "../hooks";
import { generateUrlToPrint } from "../utils";
import { useLocalStorage } from "app/authentication/hooks";
import { useDownload } from "app/download/hooks";
import { attachmentTypeLabels, attachmentTypes } from "app/forms/Attachments";

const HOST = process.env.REACT_APP_URL;
const uploadPrefix = currentConfig.UPLOAD_PREFIX;

export const validationSchema = object().shape({
  attachments: array().of(string()),
  signature: string().nullable(),
});

export const initialValues: any = {
  attachments: [],
  signature: "",
};

const CheckFieldStyled = styled(CheckField)`
  padding-bottom: 0 !important;

  label {
    position: relative;
  }

  label span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 70%;
  }
`;

const RadioFieldStyled = styled(RadioField)`
  align-items: flex-start;
  padding-bottom: 10px;

  label {
    align-items: center;
    padding-bottom: 0px;
  }
`;

const CropContainer = ({ attachmentId, crop, original }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [image, setImage] = useState(crop.url);
  const [toCrop, setToCrop] = useState(null);
  const [converting, setConverting] = useState(false);
  const [updateAttachment, { data, error, loading }] = useMutation<any>(
    UPDATE_ATTACHMENT_MUTATION
  );

  const [convertToImage, { ...convert }] = useLazyQuery<any>(
    CONVERT_ATTACHMENT_TO_IMAGE,
    {
      onCompleted: (data) => {
        if (data.convertToImage.url) {
          setToCrop(data.convertToImage);
        }
        setConverting(false);
      },
      onError: () => {
        setToCrop(null);
        setConverting(false);
      },
    }
  );

  const onConfirm = (file) => {
    updateAttachment({
      variables: {
        updateAttachmentInput: {
          where: { id: attachmentId },
          data: { crop: file.id },
        },
      },
    });
  };

  useEffect(() => {
    if (data) {
      setImage(
        data.updateAttachment.attachment.crop.url
          ? `${uploadPrefix}${data.updateAttachment.attachment.crop.url}`
          : null
      );
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setImage(null);
    }
  }, [error]);

  useEffect(() => {
    if (toCrop) {
      onOpen();
    }
  }, [toCrop]);

  const openCrop = async () => {
    setToCrop(null);
    if (original.mime === "application/pdf") {
      setConverting(true);
      if (convert.called) {
        convert
          .refetch({ id: attachmentId })
          .then(({ data }: any) => {
            if (data.convertToImage.url) {
              setToCrop(data.convertToImage);
            }
            setConverting(false);
          })
          .catch(() => {
            setToCrop(null);
            setConverting(false);
          });
      } else {
        convertToImage({
          variables: {
            id: attachmentId,
          },
        });
      }
    } else {
      setToCrop(original);
    }
  };

  return (
    <Box mt={0} mb={3}>
      <Text
        sx={{
          fontSize: 0,
          color: "gray",
          mb: 1,
        }}>
        Área útil selecionada:
      </Text>
      <Flex
        sx={{
          border: "dashed",
          width: "400px",
          height: "150px",
          mb: 2,
          justifyContent: "center",
          alignItems: "center",
          background: `url(${image})`,
          backgroundRepeat: "none",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          position: "relative",
        }}>
        {loading && <SpinnerAbsolute />}
        {!image && (
          <Text
            sx={{
              fontSize: 0,
              color: "gray",
            }}>
            Nenhuma área selecionada
          </Text>
        )}
      </Flex>
      <Button
        variant="small"
        onClick={openCrop}
        type="button"
        sx={{ position: "relative" }}>
        {converting && <SpinnerAbsolute size={30} />}
        Selecionar área útil
      </Button>
      {toCrop && (
        <CropModal
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={onConfirm}
          image={toCrop}
        />
      )}
    </Box>
  );
};

export const CropModal = ({ isOpen, onClose, onConfirm, image }) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const { uploadFile, fileResponse, loading } = useUploadFile({
    path: "docs/signatures",
  });

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      blob.lastModifiedDate = new Date().toISOString();
      blob.name = "signature_crop.png";
      blob.path = "signature_crop.png";
      uploadFile(blob);
    }, "image/png");
  };

  useEffect(() => {
    if (fileResponse) {
      onConfirm(fileResponse);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileResponse]);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
        closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          {loading && <SpinnerAbsolute />}
          <ThemeProvider theme={defaultTheme}>
            <Box p={3} sx={{ width: "100%" }}>
              <Styled.h3>Selecione a área útil da assinatura enviada</Styled.h3>

              <Cropper
                src={image.url}
                style={{ height: 400, width: "100%" }}
                aspectRatio={16 / 6}
                ref={cropperRef}
              />

              <Flex sx={{ justifyContent: "flex-end", mt: 3 }}>
                <Button variant="outline" mr={3} onClick={onClose}>
                  Fechar
                </Button>
                <Button type="button" variant="primary" onClick={onCrop}>
                  Confirmar
                </Button>
              </Flex>
            </Box>
          </ThemeProvider>
        </ModalContent>
      </Modal>
    </ThemeProvider>
  );
};

export const getSignatures = (attachments) => {
  return attachments.filter(isSignature);
};

export const isSignature = (attachment) => {
  return (
    attachment.type === attachmentTypes.digitalSignature ||
    attachment.type === attachmentTypes.manualSignature
  );
};

const hasAnotherAttachments = (attachments) => {
  return (
    attachments.filter((attachment) => !isSignature(attachment)).length > 0
  );
};

const Signatures = ({ attachments, values }) => {
  const signatures = getSignatures(attachments);
  const options = signatures.map((signature) => {
    return {
      id: signature.id,
      label: `${attachmentTypeLabels[signature.type]}`,
    };
  });

  return (
    <>
      {signatures.length > 1 && (
        <RadioFieldStyled
          options={options}
          label={`Assinaturas disponíveis:`}
          name={`signature`}
          defaultChecked={false}
          sx={{
            flexDirection: "column",
            alignItems: "flex-start",
            height: "auto",
          }}
        />
      )}

      {signatures.map((attachment) => {
        return (
          <Box key={attachment.id}>
            {signatures.length === 1 && (
              <CheckFieldStyled
                key={attachment.id}
                label={`${attachmentTypeLabels[attachment.type]}`}
                name={`signature`}
                value={attachment.id}
                defaultChecked={false}
              />
            )}

            {(values.signature === attachment.id ||
              values.signature.includes(attachment.id)) && (
              <CropContainer
                attachmentId={attachment.id}
                original={attachment.file}
                crop={attachment.crop}
              />
            )}
          </Box>
        );
      })}
      <Styled.hr />
    </>
  );
};

const getSignatureId = (signature) => {
  return Array.isArray(signature) ? signature[0] : signature;
};

const hasSignatureCrop = (formValues, data) => {
  const signatureId = getSignatureId(formValues.signature);

  if (!signatureId) {
    return true;
  }

  return Boolean(
    data.attachments.find((attachment) => attachment.id === signatureId)?.crop
      ?.url
  );
};

export const PrintModal = ({ isOpen, onClose, member }) => {
  const [token] = useLocalStorage("auth", null);
  const { ...pdf } = useDownload();

  const urlToPrint = (attachments) =>
    generateUrlToPrint({
      host: `${HOST}`,
      redirect: `redirect=/membros/${member.id}/print?attachments=${attachments}`,
      token,
    });

  const { generate, loading, data } = useGeneratePdf({
    url: urlToPrint,
    id: member.id,
    query: MEMBER_PDF_QUERY,
  });

  const download = useCallback((data) => {
    if (data?.membroPdf.url) {
      const fileUrl = data.membroPdf.url;
      pdf.download({ url: fileUrl, name: `${member.name}.pdf` });
    }
  }, []);

  useEffect(() => {
    if (data) {
      download(data);
      onClose();
    }
  }, [data]);

  return (
    <ThemeProvider theme={theme}>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ThemeProvider theme={defaultTheme}>
            <Box p={3} sx={{ width: "100%" }}>
              <Styled.h3>Exportar registo</Styled.h3>
              <Text pb={4}>Que anexos predente incluir no pdf?</Text>

              <Formik
                validateOnMount={false}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={({ attachments, signature }) => {
                  const attachmentsIds = [
                    ...attachments,
                    getSignatureId(signature),
                  ];
                  generate({ attachments: attachmentsIds });
                }}>
                {({ values }) => {
                  return (
                    <Form autoComplete="off" noValidate={true}>
                      <fieldset
                        style={{ border: "none", padding: 0 }}
                        disabled={loading}>
                        <Box py={2}>
                          <Signatures
                            attachments={member.attachments}
                            values={values}
                          />
                          {hasAnotherAttachments(member.attachments) && (
                            <Label sx={{ mb: 3 }}>Outros anexos:</Label>
                          )}

                          {member.attachments &&
                            member.attachments.length > 0 &&
                            member.attachments.map((attachment) => {
                              if (isSignature(attachment)) {
                                return false;
                              }

                              return (
                                <Box key={attachment.id}>
                                  <CheckFieldStyled
                                    key={attachment.id}
                                    label={`${
                                      attachmentTypeLabels[attachment.type]
                                    }`}
                                    name={`attachments`}
                                    value={attachment.id}
                                    defaultChecked={false}
                                  />
                                </Box>
                              );
                            })}
                        </Box>
                      </fieldset>
                      <Label
                        variant="label.small"
                        sx={{ my: 2, fontSize: 0, fontWeight: "normal" }}>
                        Obs.: Se adicionou novos anexos, guarde o formulário do
                        membro no ecrã anterior <br /> para obter os novos
                        anexos aqui.
                      </Label>
                      <Flex
                        sx={{
                          justifyContent: "flex-end",
                        }}>
                        <Button
                          variant="outline"
                          mr={3}
                          onClick={onClose}
                          disabled={loading}>
                          Fechar
                        </Button>
                        <Button
                          sx={{ position: "relative" }}
                          type="submit"
                          variant="primary"
                          disabled={!hasSignatureCrop(values, member)}>
                          Exportar
                          {loading && (
                            <SpinnerAbsolute size={24} strokeWidth={3} p={0} />
                          )}
                        </Button>
                      </Flex>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </ThemeProvider>
        </ModalContent>
      </Modal>
    </ThemeProvider>
  );
};
