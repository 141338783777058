import { format, parse } from "date-fns";
import cloneDeep from "lodash.clonedeep";
import { object } from "yup";

import { currentConfig } from "@members/config";
import { MEMBERS_QUERY, DASHBOARD_QUERY } from "@members/graphql";

import AddressData from "app/forms/AddressData";
import Attachments from "app/forms/Attachments";
import ChurchData from "app/forms/ChurchData";
import ContactInformation from "app/forms/ContactInformation";
import GeneralInformation from "app/forms/GeneralInformation";
import PersonalData from "app/forms/PersonalData";
import StatusData from "app/forms/StatusData";
import { MEMBER_STATUS } from "app/members";

const uploadPrefix = currentConfig.UPLOAD_PREFIX;

export const validationSchema = object()
  .shape({})
  .concat(StatusData.validationSchema)
  .concat(PersonalData.validationSchema)
  .concat(ChurchData.validationSchema)
  .concat(AddressData.validationSchema)
  .concat(GeneralInformation.validationSchema)
  .concat(ContactInformation.validationSchema)
  .concat(Attachments.validationSchema);

export const initialValues = {
  ...StatusData.initialValues,
  ...PersonalData.initialValues,
  ...ChurchData.initialValues,
  ...AddressData.initialValues,
  ...GeneralInformation.initialValues,
  ...ContactInformation.initialValues,
  ...Attachments.initialValues,
};

export const options = {
  refetchQueries: [
    {
      query: MEMBERS_QUERY,
    },
    {
      query: DASHBOARD_QUERY,
    },
  ],
};

export const serializeMember = (data) => {
  const {
    attachments = null,
    documentType,
    __typename,
    created_at,
    id,
    updated_at,
    ...values
  } = data;

  if (values.picture) {
    values.picture = values.picture.id;
  }

  if (values.dateOfBaptism) {
    values.dateOfBaptism = format(
      parse(values.dateOfBaptism, "dd/MM/yyyy", new Date()),
      "yyyy-MM-dd"
    );
  }

  if (values.dateOfBirth) {
    values.dateOfBirth = format(
      parse(values.dateOfBirth, "dd/MM/yyyy", new Date()),
      "yyyy-MM-dd"
    );
  }

  if (values.status) {
    values.status = MEMBER_STATUS.active;
  } else {
    values.status = MEMBER_STATUS.pending;
  }

  values.attachments = attachments.map((attachment) => attachment.id);

  return values;
};

export const deserializeMember = (data) => {
  const values = cloneDeep(data);
  values.congregation = data.congregation?.id;
  values.memberType = data.memberType.id;
  values.baptismMethod = data.baptismMethod?.id;
  values.civilStatus = data.civilStatus.id;

  values.attachments = data.attachments.map((attachment) => {
    return {
      ...attachment,
      file: {
        ...attachment.file,
        url: attachment.file ? `${uploadPrefix}${attachment.file.url}` : null,
      },
      crop: {
        ...attachment.crop,
        url: attachment.crop ? `${uploadPrefix}${attachment.crop.url}` : null,
      },
    };
  });

  if (values?.picture?.formats?.thumbnail.url) {
    if (!values.picture.formats.thumbnail.url.startsWith(uploadPrefix)) {
      values.picture.formats.thumbnail.url = `${uploadPrefix}${values.picture.formats.thumbnail.url}`;
    }
  }

  if (values?.picture?.url) {
    if (!values.picture.url.startsWith(uploadPrefix)) {
      values.picture.url = `${uploadPrefix}${
        values.picture.formats.large?.url || values.picture.url
      }`;
    }
  }

  if (data.status === MEMBER_STATUS.active) {
    values.status = true;
  } else {
    values.status = false;
  }

  values.dateOfBirth = format(
    parse(values.dateOfBirth, "yyyy-mm-dd", new Date()),
    "dd/mm/yyyy"
  );

  if (data.dateOfBaptism) {
    values.dateOfBaptism = format(
      parse(data.dateOfBaptism, "yyyy-mm-dd", new Date()),
      "dd/mm/yyyy"
    );
  }

  return values;
};
