import { object, string } from "yup";

import { CONGREGATIONS_QUERY, DASHBOARD_QUERY } from "@members/graphql";

export const validationSchema = object().shape({
  name: string().required("Obrigatório"),
});

export const initialValues: any = {
  name: "",
};

export const options = {
  refetchQueries: [
    {
      query: CONGREGATIONS_QUERY,
    },
    {
      query: DASHBOARD_QUERY,
    },
  ],
};
