import { useFormikContext } from "formik";
import React from "react";
import { Box, Styled, Grid, Divider, Label } from "theme-ui";
import { object, string } from "yup";

import { TextField, SelectField } from "@members/fields";
import { EDUCATION_QUERY } from "@members/graphql";
import { useQuerySelectOptions, useQueryRadioOptions } from "@members/hooks";

import { Title } from "app/utils";

const validationSchema = object().shape({
  identificationDocument: string().required("Obrigatório"),
  taxpayerNumber: string().required("Obrigatório"),
  occupation: string().required("Obrigatório"),
  education: string().required("Obrigatório"),
});

const initialValues: any = {
  identificationDocument: "",
  taxpayerNumber: "",
  occupation: "",
  education: "",
};

export const GeneralInformation = () => {
  const education = useQuerySelectOptions(EDUCATION_QUERY, "educations");

  return (
    <>
      <Styled.h3>Informações gerais</Styled.h3>
      <Divider />
      <Grid
        sx={{
          gridTemplateColumns: ["1fr", null, "1fr 1fr"],
          gap: [0, null, 4],
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <TextField
            label="Número do Documento de Identificação"
            name="identificationDocument"
            required
          />
        </Box>
        <Box sx={{ gridColumn: [1, null, 2] }}>
          <TextField
            label="Número de Contribuinte"
            name="taxpayerNumber"
            mask="99999999999"
            maskPlaceholder={null}
            type="tel"
            required
          />
        </Box>
      </Grid>

      <TextField label="Profissão" name="occupation" required />
      <SelectField label="Habilitações Literarias" name="education" required>
        {education.options}
      </SelectField>
    </>
  );
};

export const GeneralInformationPrint = () => {
  const { values } = useFormikContext<any>();
  const education = useQueryRadioOptions(EDUCATION_QUERY, "educations");

  const memberEducation = education?.options?.find(
    (i) => i.id == values.education
  );

  return (
    <>
      <Title>Informações gerais</Title>
      <Grid
        sx={{
          gridTemplateColumns: "1fr 1fr 160px",
          rowGap: 2,
          columnGap: 2,
          mb: 2,
          px: 2,
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <Label mb={1} variant="label.small">
            Documento de Identificação
          </Label>
          {values.identificationDocument}
        </Box>
        <Box sx={{ gridColumn: 2 }}>
          <Label mb={1} variant="label.small">
            Número de Contribuinte
          </Label>
          {values.taxpayerNumber}
        </Box>
        <Box sx={{ gridColumn: 1 }}>
          <Label mb={1} variant="label.small">
            Profissão
          </Label>
          {values.occupation}
        </Box>
        <Box sx={{ gridColumn: 2 }}>
          <Label mb={1} variant="label.small">
            Habilitações Literarias
          </Label>
          {memberEducation?.label}
        </Box>
      </Grid>
    </>
  );
};

export default {
  Page: GeneralInformation,
  Print: GeneralInformationPrint,
  initialValues,
  validationSchema,
};
