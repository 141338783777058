import React from "react";
import { Flex, Text } from "theme-ui";

export const Footer = () => {
  return (
    <Flex variant="layout.admin.footer">
      <Flex
        sx={{
          alignItems: "center",
        }}>
        <Text color="gray.">MSBN {new Date().getFullYear()}</Text>
      </Flex>
    </Flex>
  );
};
