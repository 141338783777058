import { useQuery } from "@apollo/react-hooks";
import { DocumentNode } from "graphql";
import { useState, useEffect } from "react";

interface UseGeneratePdfProps {
  url: (props: any) => string;
  id: string;
  query: DocumentNode;
}

interface GenerateProps {
  attachments: string[];
}

interface UseGeneratePdfResponse {
  generate: (props: GenerateProps) => void;
  loading: boolean;
  error: boolean;
  data: any;
}

export const useGeneratePdf = ({
  url,
  id,
  query,
}: UseGeneratePdfProps): UseGeneratePdfResponse => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const { ...pdf } = useQuery(query, {
    skip: true,
  });

  const generate = ({ attachments }) => {
    setLoading(true);
    setData(null);
    if (pdf.called) {
      pdf
        .refetch({
          id,
          url: url(attachments),
          attachments,
        })
        .then((props: any) => {
          setData(props.data);
        })
        .catch(() => setError(true));
    }
  };

  useEffect(() => {
    if (data || error) {
      setLoading(false);
    }
  }, [data, error]);

  return { generate, loading: loading || pdf.loading, error, data };
};
