import { Formik, Form } from "formik";
import React, { useRef } from "react";
import { Redirect } from "react-router";
import { Flex, Styled, Box, Button } from "theme-ui";
import { object, string } from "yup";

import { TextField } from "@members/fields";

import { useAuthentication } from "app/authentication/hooks";
import { MEMBERS_ROUTES } from "app/members/MemberRoutes";

const initialValues = {
  identifier: "",
  password: "",
};

const validationSchema = object().shape({
  identifier: string().required("Obrigatório"),
  password: string().required("Obrigatório"),
});

export const LoginView = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const redirect = urlParams.get("redirect");
  const { login, isAuthenticated } = useAuthentication(token);
  const form = useRef(null);

  if (isAuthenticated) {
    return <Redirect to={redirect || MEMBERS_ROUTES.list} />;
  }

  return (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}>
      <Box
        sx={{ width: ["100%", "400px"], bg: "white", p: 4, borderRadius: 2 }}>
        <Formik
          validateOnMount={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            login(form);
          }}>
          {({ isValid }) => {
            return (
              <Form autoComplete="off" noValidate={true} ref={form}>
                <Flex sx={{ flexDirection: "column" }}>
                  <Box sx={{ mb: 3 }}>
                    <Styled.h3>Autenticação</Styled.h3>
                  </Box>
                  <Box>
                    <TextField name="identifier" label="Username" />
                  </Box>
                  <Box>
                    <TextField
                      name="password"
                      label="Password"
                      type="password"
                    />
                  </Box>
                  <Flex sx={{ justifyContent: "flex-end" }}>
                    <Button disabled={!isValid} type="submit">
                      Entrar
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Flex>
  );
};
