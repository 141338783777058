import { Icon } from "@tooca-ui/icons";
import React from "react";
import { Flex, Text } from "theme-ui";

import { Link } from "@members/layout";

export const Sidebar = () => {
  return (
    <Flex
      variant="layout.admin.sidebar"
      sx={{ p: 4, flexDirection: "column", alignItems: "flex-start" }}>
      <Link to="/">
        <Flex sx={{ alignItems: "center" }}>
          <Icon name="Dashboard" size={20} />
          <Text py={2} pl={2}>
            Dashboard
          </Text>
        </Flex>
      </Link>
      <Link to="/membros">
        <Flex sx={{ alignItems: "center" }}>
          <Icon name="Membership" size={20} />
          <Text py={2} pl={2}>
            Membros e Congregados
          </Text>
        </Flex>
      </Link>
      <Link to="/congregacoes">
        <Flex sx={{ alignItems: "center" }}>
          <Icon name="House" size={20} />
          <Text py={2} pl={2}>
            Congregações
          </Text>
        </Flex>
      </Link>
      <Link to="/utilizadores">
        <Flex sx={{ alignItems: "center" }}>
          <Icon name="Users" size={20} />
          <Text py={2} pl={2}>
            Users
          </Text>
        </Flex>
      </Link>
      {/* <Link to="/">
        <Text py={2}>Formulário de Registo</Text>
      </Link> */}
    </Flex>
  );
};
