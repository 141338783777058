import { useFormikContext } from "formik";
import React from "react";
import { Box, Styled, Grid, Divider, Label } from "theme-ui";
import { object, string } from "yup";

import { TextField } from "@members/fields";

import { Title } from "app/utils";

const validationSchema = object().shape({
  address: string().required("Obrigatório"),
  locale: string().required("Obrigatório"),
  village: string().required("Obrigatório"),
  district: string().required("Obrigatório"),
  postalCode: string()
    .matches(/^\d{4}-\d{3}$/, "Código postal inválido")
    .required("Obrigatório"),
});

const initialValues: any = {
  address: "",
  locale: "",
  village: "",
  district: "",
  postalCode: "",
};

export const AddressData = () => {
  return (
    <>
      <Styled.h3>Dados de morada</Styled.h3>
      <Divider />

      <TextField label="Endereço" name="address" required />

      <Grid
        sx={{
          gridTemplateColumns: ["1fr", null, "1fr 1fr"],
          rowGap: 0,
          columnGap: [0, null, 4],
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <TextField label="Freguesia" name="locale" required />
        </Box>
        <Box sx={{ gridColumn: [1, null, 2] }}>
          <TextField label="Concelho" name="village" required />
        </Box>
        <Box sx={{ gridColumn: 1 }}>
          <TextField label="Distrito" name="district" required />
        </Box>
        <Box sx={{ gridColumn: [1, null, 2] }}>
          <TextField
            label="Código Postal"
            name="postalCode"
            mask="9999-999"
            type="tel"
            required
          />
        </Box>
      </Grid>
    </>
  );
};

export const AddressDataPrint = () => {
  const { values } = useFormikContext<any>();
  return (
    <>
      <Title>Dados de morada</Title>

      <Grid
        sx={{
          gridTemplateColumns: "1fr 1fr 160px",
          rowGap: 2,
          columnGap: 2,
          mb: 2,
          px: 2,
        }}>
        <Box sx={{ gridColumn: "1/3" }}>
          <Label mb={1} variant="label.small">
            Endereço
          </Label>
          {values.address}
        </Box>
        <Box sx={{ gridColumn: 1 }}>
          <Label mb={1} variant="label.small">
            Freguesia
          </Label>
          {values.locale}
        </Box>
        <Box sx={{ gridColumn: 2 }}>
          <Label mb={1} variant="label.small">
            Concelho
          </Label>
          {values.village}
        </Box>
        <Box sx={{ gridColumn: 1 }}>
          <Label mb={1} variant="label.small">
            Distrito
          </Label>
          {values.district}
        </Box>
        <Box sx={{ gridColumn: 2 }}>
          <Label mb={1} variant="label.small">
            Código Postal
          </Label>
          {values.postalCode}
        </Box>
      </Grid>
    </>
  );
};

export default {
  Page: AddressData,
  Print: AddressDataPrint,
  initialValues,
  validationSchema,
};
