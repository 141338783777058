"use strict";

exports.__esModule = true;
exports.currentConfig = exports.config = void 0;
var config = {
  development: {
    API: "http://localhost:1337",
    PUBLIC_URL: "/",
    APP_URL: "https://msbnportugal.com/registo",
    UPLOAD_PREFIX: "http://localhost:1337"
  },
  test: {},
  production: {
    API: "https://api.membros.msbnportugal.com",
    PUBLIC_URL: "/registo",
    UPLOAD_PREFIX: ""
  }
};
exports.config = config;

var currentConfig = function () {
  if (process.env.NODE_ENV) {
    return config[process.env.NODE_ENV];
  }

  return null;
}();

exports.currentConfig = currentConfig;